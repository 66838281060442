h1,
h2,
h3,
h4 {
  font-family: var(--heading);
  line-height: calc(var(--spacing) - 0.2);
}

h1 {
  margin: 0px 0 0px 0;
  font-size: 37px;
  font-weight: var(--regular);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
}

h2 {
  margin: 40px 0 20px 0;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--light-gray);
  font-size: var(--xl);
  font-weight: var(--regular);
  letter-spacing: 1px;
  text-align: left;
}

h3 {
  margin: 40px 0 20px 0;
  font-size: var(--large);
  font-weight: var(--semi-bold);
  text-align: left;
}

h4 {
  margin: 40px 0 20px 0;
  font-size: var(--medium);
  font-weight: var(--semi-bold);
  text-align: left;
}

:where(h1, h2, h3, h4) > .icon {
  margin-right: 1em;
  color: var(--light-gray);
}
